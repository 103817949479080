.App {
  
  background-color: #000000
}

.Wrapper {
  background-color: #FECD45
  }
  
.Main {
  background-color: #000000
    }



.Body {
  background-color: rgb(0, 0, 0)
}



.Mid-bar {
  background-color: #FECD45;
  color: #000000;
  z-index: 10;
}

.Top-title {
  color: rgb(0, 0, 0) ;
  z-index: 10;
}



 .divBox {
  background-color: black;   
}


.navBarLink3 {
  color: black;
  background-color: #FECD45; 
}
.navBarLink3:hover {
  color: rgb(255, 255, 255);
  background-color: #000000; 
}

.navBarLink3blue {
  color: rgb(255, 255, 255);
  background-color: #2568FB;; 
}

.navBarLink3blue:hover {
  color: rgb(0, 0, 0);
  background-color: #ffffff; 
}

.navBarLink4 {
  color: #2568FB;
  background-color: #FECD45; 
}

.navBarLinkActive {
  
  color: #2568FB ; 
  
}

.navBarTitle {
  color:#000000;
  
}

 .navBarBox {
    background-color:  #FECD45;
  }


.pageContent {
  color: #000000;
  background-color: #FECD45;
}

.Content404 {
  color: #FECD45;
  background-color: black ;
}

.pageContentSmall {
  color: #ffffff;
  background-color: #000000;
}

.pageContentSmallLeft {
  color: #ffffff;
  background-color: #000000;
  padding-left: 2vw;
}

.pageContent2 {
  color: #FECD45;
  background-color: black ;
}

.pageContentBold {
  color: #000000;
  background-color: #FECD45;
}

.pageContentA {
  color: #ffffff;
  
}

.pageContentH {
  color: #ffffff;
  
}


.pageContentMed2 {
  color: rgb(0, 0, 0);
}

.pageContentHedder {
  color: #ffffff    ;
}

.pageContentHedderH {
  color: #ffffff    ;
}

.pageContentHedderLower {
  color: #ffffff ;
  
}

.pageContentHedderLowerH {
  color: #ffffff ;  
}


.pageContentHedder2 {
  color: #000000 ;
  border-color: #2568FB;
  background-color: #FECD45 ;
}



.helpContent {
  color: #e0e0e0;

}


.imageBorder {
  color: white;
}

.boxBorder {
box-shadow: inset 0 0 0 1px grey;
}

.boxBorder2 {
  border-color: #FECD45;
  }

  .boxBorder3 {
    border-color: #2568FB;
    border-style: solid;
    border-left-width: Calc(10px + 5vh) ;
    border-right-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-radius: 8px;
    
    
    }


.tosContentSmall2 {
  color: #e0e0e0;;
}

.tosContentBold {
  color: #FECD45;
}

.tosContentTitle {
  color: #FECD45
}



.helpContentHedder {
  color: #FECD45
}

.helpContentTitle {
  color: #ffffff;
  
}

.helpContentSmall2 {
  color: #e0e0e0
}











.Misc-button {
  background-color: #0F4C5C;
  color: white ;
}

.SO-button {
  background-color:  #9A031E;
  color: white;
}


.RB-button {
  background-color: #FB8B24;
  color: white;
}


.SP-button {
  background-color: #ce5a12;
  color: white;
}




