@import "colors.ncs1.css";

.App {
position: relative;
min-height:100vh;
z-index: 0;

}

.Wrapper {
  position: relative;
z-index: 0;
min-height: 100vh;
animation-name: div-bg-fade2;
animation-duration: .5s;

  }
  

.Main {
  position: relative;
z-index: 0;
background-color: rgb(0, 0, 0);
min-height: 50.48vw;
  }


  
  .Body {
    position: relative;
  z-index: 0;
  background-color: rgb(0, 0, 0);


    }
    
    .fadeshow2 { 
      display:none; 
 
      
    }

.navBarTitle {
    font-size: 4vw;  
}

.navBarBox {
  
  
  top :0;
  text-align: center;
  font-weight: bold;
  
  
}


.navBarLink3 {
  
  
  font-size: calc(5px + 1vw);
  min-width: calc(15px + 9vw);
  border-radius: 30px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  padding-left: 0vw;
  padding-right:0vw;
}

.navBarLink3blue {
  font-size: calc(5px + 1vw);
  min-width: calc(15px + 9vw);
  padding-left:0vw;
  padding-right:0vw;
  border-radius: 30px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}






.Mid-bar {
  font-Size:2.2vw;
  padding-left: 8vw ;
  padding-right: 8vw ;
 
  
  min-width:48.25vw
}


.pageContent {
  text-align: center;
  
}


.pageContent2 {
  font-size: 1.5vw;

  margin-top: 1vw;
  margin-left: 14vw;
  margin-right:25vw;
}



.pageContentA {
 position: relative;
 text-align: center;
 z-index: 0;
 padding-bottom: 2.5vw;
 
}

.pageContentH {
 position: relative;
 text-align: center;
 z-index: 0;
 padding-bottom: 2.8vw;
 margin-right:25vw;
}

.pageContentBold {
 text-align: center;
 font-weight: bold;
}


.pageContentSmall {
 text-align: center;
 font-size: 2vw;
 margin-left: 5%;
 
}

.pageContentSmallLeft {
 text-align: left;
 font-size: 1.15vw;
 margin-left: 12vw;
 padding-left: 4vw;
 margin-right:25vw;
}

.pageContentHedder {
  text-align: center;
  
}

.pageContentHedderLowerH {
  text-align: center;
  margin-right:2vw;
}


.pageContentHedderH {
  text-align: right;  
  padding-left: 10vw;
}

.pageContentHedder2 {
  visibility:hidden;
  border-style: solid;
  border-width: 0px;
  border-radius: 8px;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-top: 0vh;
  margin-left: 0vw;
  margin-right: 0vw;
  min-width: 46vw;
}


.helpContent {
  text-align: center;
}


.helpContentSmall2 {
  text-align: left;
  font-size: 1.3vw;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}

.helpContentTitle {
  text-align: center;
  font-size: 2vw;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}

.helpContentHedder {
  text-align: left;
  font-size: 2vw;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}


.tosContentSmall2 {
  text-align: left;
  font-size: 1.3vw;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0%;
}

.tosContentTitle {
  text-align: center;
  font-size: 2vw;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}

.Content404 {
  text-align: center;
  font-size: 5vh;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}



.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vh);

}



.boxBorder2 {
  
  border-style:ridge;
  border-left-width: 3vw ;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-radius: .5vw;
  margin-top: 2vw;
  mix-blend-mode: exclusion;
  z-index: 15;
  margin-left: 5vw;
  margin-right: 0vw;

  background-color: black;
  animation-name: div-bg-fade;
  animation-duration: .5s;
  }



.div-bgr {
  
  background-color: black;
  

  position: absolute ;
   top: 14.15vw;
   right: 0; 
   
}

.div-bgl {
  
  background-color: black;
  

  position: absolute ;
   top: 26.32vw;
   left: 0; 
   
}

.div-bgb {
  
  position: absolute ;
  min-height: 29.5vw;
  max-height: 29.5vw;
  min-width: 100vw;

  background-color: rgb(0, 0, 0);
  
}



@keyframes div-bg-fade {
  0%   {opacity: 0;}
  10%  {opacity: .1;}
  20%  {opacity: .2;}
  30%  {opacity: .3;}
  
  50% {opacity: .5;}
  75% {opacity: .75;}
}

@keyframes div-bg-fade2 {
  0%   {opacity: 0;}
  10%  {opacity: .1;}
  20%  {opacity: .2;}
  30%  {opacity: .3;}
  40%  {opacity: .4;}
  50% {opacity: .5;}
  60% {opacity: .6;}
  70% {opacity: .7;}
  80% {opacity: .8;}
  90% {opacity: .9;}
  100% {opacity: 1;}
}


.App-logo {
  height: 40px;
  pointer-events: none;
}
 

@media (max-width: 860px) {
  .Wrapper {
    background-color: #FECD45;
    min-height: 100vh;
    min-width: 100vw;
  }
.Body{ 

  min-height: calc(75px + 47vw);  
  background-color: rgb(0, 0, 0);
}
  .fadeshow1 { 
    display: none; 
  }
  .fadeshow2 { 
    display: inline; 
  }
  .pageContentA {
    padding-top: 1.5vw;
  }
  .pageContent2 {
    font-size: calc(1.3vw + 3px);
    margin-top: .5vw;
    margin-left:5vw;
    margin-right:6vw;
    padding-top: 0.55vw;
  }
  .pageContentSmallLeft {
      text-align: left;
      font-size: calc(1.3vw + 2px);
      margin-left: 6vw;
      padding-left: 0vw;
      margin-right:4vw;
      margin-top: 0.25vw;
      margin-bottom: 0.25vw;
  }
  .pageContentH {
    margin-right:19vw;
    
   }   
   .pageContentHedderLowerH { 
    margin-right:0vw;
    margin-left: 18vw;
    padding-bottom: 2vw;
  }
  .pageContentHedderH {
    padding-left: 0vw;
    margin-left: 16vw;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

